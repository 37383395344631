<template>
  <div class="ReviewIntention">
    <div class="form">
      <el-form
        ref="formRefReview"
        :inline="true"
        label-width="100px"
        :model="form"
        @submit.native.prevent
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="姓名" prop="patientName">
              <el-input
                v-model="form.patientName"
                clearable
                placeholder="请输入姓名"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="手机号" prop="phone">
              <el-input
                v-model="form.phone"
                clearable
                placeholder="请输入手机号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="身份证号" prop="idCardNo">
              <el-input
                v-model="form.idCardNo"
                clearable
                placeholder="请输入身份证号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="微信绑定医生" prop="docOpenId">
              <el-select
                v-model="form.docOpenId"
                clearable
                placeholder="请选择微信绑定医生"
              >
                <el-option
                  v-for="item in BindDoctorList"
                  :key="item.key"
                  :label="item.docName"
                  :value="item.openId"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="预约时间" prop="makeTime">
              <el-date-picker
                v-model="form.makeTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="检查项目" prop="ciId">
              <el-select
                v-model="form.ciId"
                clearable
                placeholder="请选择检查项目"
              >
                <el-option
                  v-for="item in InspectionList"
                  :key="item.ciId"
                  :label="item.ciName"
                  :value="item.ciId"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="创建时间" prop="createTime">
              <el-date-picker
                v-model="form.createTime"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item class="TestKitList-form-button">
              <el-button
                @click="onSubmit(form)"
                type="primary"
                native-type="submit"
                >查询</el-button
              >
              <el-button @click="Reset('formRefReview')">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div class="list">
      <div class="TestKitList-list-handle">
        <el-button @click="allExport" type="primary" size="small"
          >导出全部数据</el-button
        >
      </div>
      <div>
        <el-table
          :height="470"
          :data="tableList"
          v-loading="tableLoad"
          element-loading-text="拼命加载中..."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          style="width: 100%"
          @sort-change="filterChange"
        >
          <el-table-column
            prop="serialNumber"
            align="center"
            label="序号"
            width="80"
          ></el-table-column>
          <el-table-column
            prop="createTime"
            align="center"
            label="创建时间"
            sortable
          >
            <template #default="{ row }">
              <span>{{ timeConvert(row.createTime) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="patientName"
            align="center"
            label="患者姓名"
          ></el-table-column>
          <el-table-column
            prop="phone"
            align="center"
            label="联系方式"
          ></el-table-column>
          <el-table-column
            prop="idCardNo"
            align="center"
            label="身份证号"
          ></el-table-column>
          <el-table-column prop="sex" align="center" label="性别">
            <template #default="{ row }">
              <span>{{ GenderConvert(row.sex) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="age"
            align="center"
            label="年龄"
          ></el-table-column>
          <el-table-column prop="ciId" align="center" label="检查项目">
            <template #default="{ row }">
              <span>{{ ciIDConvert(row.ciId) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="makeTime"
            align="center"
            label="预约时间"
            sortable
          >
            <!-- <template #default="{ row }">
                            <span>{{ timeConvert(row.makeTime) }}</span>
                        </template> -->
          </el-table-column>
          <el-table-column
            prop="doctorName"
            align="center"
            label="微信绑定医生"
          ></el-table-column>
        </el-table>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.pageNum"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
// import dayjs from "dayjs";

export default {
  data() {
    return {
      form: {
        patientName: "",
        phone: "",
        idCardNo: "",
        docOpenId: "",
        makeTime: [],
        ciId: "",
        createTime: [],
        makeOrderBy: "",
        createOrderBy: "desc",
        pageNum: 1,
        pageSize: 10,
      },
      tableList: [],
      total: 0,
      BindDoctorList: [],
      InspectionList: [],
      tableLoad: false,
      GenderList: [],
    };
  },
  mounted() {
    this.InspectionFn();
    this.BindDoctorFn();
    this.get_list();
    this.fitGenderList();
  },
  methods: {
    GenderConvert(sex) {
      let str = "";

      let arr = (this.GenderList || []).filter((item) => item.dictKey == sex);

      if (arr.length > 0) str = arr[0].dictValue;
      return str;
    },
    ciIDConvert(id) {
      let str = "";

      let arr = (this.InspectionList || []).filter((item) => item.ciId == id);

      if (arr.length > 0) str = arr[0].ciName;
      return str;
    },

    timeConvert(time) {
      let date = new Date(time);
      // 获取年、月、日、时、分、秒
      let year = date.getFullYear();
      let month = ("0" + (date.getMonth() + 1)).slice(-2);
      let day = ("0" + date.getDate()).slice(-2);
      let hour = ("0" + date.getHours()).slice(-2);
      let minute = ("0" + date.getMinutes()).slice(-2);
      let second = ("0" + date.getSeconds()).slice(-2);
      // 拼接成年月日时分秒格式
      let formattedDate = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
      return formattedDate;
    },

    onSubmit() {
      this.form.pageNum = 1;
      this.get_list();
    },
    allExport() {
      this.$FilePost("hh/make/exportMake", {
        ...this.form,
      }).then((res) => {
        if (!res) return false;
        console.log(res, "数据...");
      });
    },

    filterChange(e) {
      console.log(e);
      if (e.prop == "createTime") {
        this.form.makeOrderBy = "";
        if (e.order == "descending" || e.order == null) {
          this.form.createOrderBy = "desc";
        } else {
          this.form.createOrderBy = "asc";
        }
      } else {
        this.form.createOrderBy = "";
        if (e.order == "descending" || e.order == null) {
          this.form.makeOrderBy = "desc";
        } else {
          this.form.makeOrderBy = "asc";
        }
      }

      this.get_list();
    },

    Reset(formRefReview) {
      this.$refs[formRefReview].resetFields();
      this.$nextTick(() => {
        this.get_list();
      });
    },

    fitGenderList() {
      this.$get("base/dict/fitGender").then((res) => {
        if (!res) return false;
        console.log(res, "性别...");
        this.GenderList = res.data;
      });
    },

    get_list() {
      this.$post("hh/make/findMakeList", {
        ...this.form,
      }).then((res) => {
        if (!res) return false;
        let data_ = res.data;
        console.log(data_, "数据...");

        this.total = data_.total;
        this.tableList = data_.list;
      });
    },
    BindDoctorFn() {
      this.$get("hh/polypInfo/findDoctorList").then((res) => {
        if (!res) return false;
        console.log(res, "医生...");

        this.BindDoctorList = res.data;
      });
    },
    InspectionFn() {
      this.$post("hh/make/findCheck").then((res) => {
        if (!res) return false;
        console.log(res, "项目....");
        this.InspectionList = res.data;
      });
    },
    handleCurrentChange(e) {
      this.form.pageNum = e;
      this.get_list();
    },
    handleSizeChange(e) {
      this.form.pageSize = e;
      this.get_list();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/TestKit/TestKitList";
</style>
